import { render, staticRenderFns } from "./ReportSQC.vue?vue&type=template&id=2388205f"
import script from "./ReportSQC.js?vue&type=script&lang=js&external"
export * from "./ReportSQC.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports